.imageUploadNew {
    &-container {
        width: fit-content;
        height: 100%;
        display: flex;
        align-items: center;
        border-radius: 15px;
        padding: 1em;
        img {
            height: 100%;
            max-height: 100px;
            width: auto;
            @media screen and (max-width: 768px) {
                max-height: 50px;
            }
        }
    }
}
