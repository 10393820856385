.InitialProposal {
    &-topbar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 1em 0;
        flex-wrap: wrap;
        row-gap: 1em;
        .left {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1em;
            h2 {
                margin: 0;
            }
            .button {
                margin-left: 5px;
            }
        }
        .adders {
            display: flex;
            justify-content: flex-start;
            .button {
                margin: auto 1em;
            }
        }
    }
    .scrolled {
        position: sticky;
        width: calc(100% + 2em);
        margin-left: -1em;
        padding: 1em 1em;
        z-index: 9;
        top: 0;
        background-color: white;
    }
    &-content {
        .meta {
            margin-top: 1em;
            display: flex;
            flex-direction: row;
            gap: 1em;
            .MD {
                display: flex;
                flex-direction: row;
                gap: 0.5em;
                align-items: center;
            }

            .BItem {
                height: 100%;

                .input {
                    height: 100%;
                }
            }
        }
        .ui.form textarea {
            height: 5em;
        }
        .Topic {
            .top {
                margin-bottom: 1em;
                display: flex;
                justify-content: end;
            }
            &-header {
                margin-bottom: 1em;
                display: flex;
                justify-content: end;
            }
            .content,
            .content-editing {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                grid-template-rows: 1fr auto;
                grid-column-gap: 1em;
                grid-row-gap: 1em;
                & > div:nth-child(1) {
                    grid-area: 1 / 1 / 2 / 4;
                }
                & > div:nth-child(2) {
                    grid-area: 1 / 5 / 2 / 6;
                }
                & > div:nth-child(3) {
                    grid-area: 2 / 1 / 3 / 6;
                }

                .radios {
                    display: flex;
                    flex-direction: column;
                    gap: 1em;
                    justify-content: flex-start;
                    .ui.checkbox label {
                        color: var(--card-text-color) !important;
                    }
                }

                &-name {
                    font-size: 16px;
                }

                .locked {
                    pointer-events: none;
                }

                @media screen and (max-width: 700px) {
                    display: flex;
                    flex-direction: column;
                }
            }
            // .content,
            // .content-editing {
            //     display: grid;
            //     grid-template-rows: 1fr;
            //     grid-column-gap: 1em;
            //     grid-row-gap: 0px;

            // }
        }
        .Meeting {
            .top {
                margin-bottom: 1em;
                display: flex;
                justify-content: end;
            }
            .content {
                display: grid;
                grid-template-columns: 3fr 2fr 0.5fr;
                grid-column-gap: 1em;
                &-editing {
                    display: grid;
                    grid-column-gap: 1em;
                    grid-template-columns: 3fr 0.5fr 0.5fr 0.25fr;
                }
            }
        }
        .AS {
            display: flex;
            flex-direction: row;
            align-items: center;
            > * {
                margin: 0.5em;
            }
            .locked {
                pointer-events: none;
            }
        }
        .radios {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
        }
    }
    @media screen and (max-width: 700px) {
        &-content .meta {
            flex-direction: column;
            .MD {
                justify-content: space-between;
            }
        }
        &-topbar {
            .editButton {
                display: none;
            }
        }
        .Topic {
            .content {
                display: flex;
                flex-direction: column;
                gap: 1em;
            }
        }
        .Meeting {
            .content {
                grid-column-gap: 1 !important;
                grid-template-columns: 3fr 1.5fr 1.5fr !important;
            }
        }
    }
}
