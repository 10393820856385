.InvoicingMobile {
    @media screen and (min-width: 700px) {
        display: none;
    }
}

.Invoicing {
    @media screen and (max-width: 700px) {
        display: none;
    }
    .coach-info {
        display: flex;
        flex-direction: column;
        gap: 0.25em;
        &-content {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            flex-wrap: wrap;
            row-gap: 1em;
            .content-section {
                padding: 0 1em;
                display: flex;
                flex-direction: column;
                gap: 0.25em;
            }
            @media screen and (max-width: 700px) {
                flex-direction: column;
                gap: 1em;
                .content-section {
                    flex-basis: 1;
                }
            }
            .content-section {
                flex-basis: 50%;
                .field {
                    justify-content: space-between;
                    span {
                        font-weight: bold;
                    }
                    display: flex;
                    gap: 1em;
                }
            }
        }
        .row {
            width: 100% !important;
            display: flex;
            justify-content: space-between;
            div[role="option"] {
                width: 100%;
            }
            div {
                color: black;
                width: 50%;
            }
        }
    }
}
.InvoiceCreator {
    width: 100vw;
    // height: calc(100vh - 60px);
    position: fixed;
    height: calc(100vh - 60px);
    top: 0;
    left: 0;
    z-index: 300;
    background-color: rgba(0, 0, 0, 0.227);
    padding: 1em 0;
    .ui.container {
        height: unset;
    }
    .top {
        display: flex;
        justify-content: space-between;
    }
    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        // overflow-y: scroll;
        .creator-buttons {
            padding: 1em 0;
            display: flex;
            justify-content: space-between;
        }
    }
    .steps {
        display: flex;
        justify-content: space-around;
        gap: 1em;
        align-items: center;
        .step {
            display: flex;
            flex-direction: column;
            align-items: center;
            .stepIndex {
                background-color: red;
                width: fit-content;
                font-size: 2em;
                padding: 1em;
                border-radius: 50%;
            }
        }
        .line {
            height: 20px;
            background-color: red;
            width: 25%;
        }
    }
}
.invoiceRecords {
    margin: 1em 0;
    overflow-y: scroll;
    height: 500px;
    thead {
        top: 0;
        position: sticky;
        z-index: 3;
    }
    .totals {
        width: 100%;
        background-color: var(--secondary-color);
        color: var(--secondary-color-text);
        padding: 1em;
        position: sticky;
        bottom: 0;
        z-index: 3;
        margin-top: -1em;
        display: flex;
        gap: 2em;
        align-items: center;
    }
    tfoot {
        position: sticky;
        bottom: 0;
        z-index: 3;
    }
}
.invoiceDetails {
    height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
    &-FormGrid {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: auto auto;
        grid-column-gap: 2em;
        grid-row-gap: 0px;
        padding: 1em;
        .coach,
        .bankDetails,
        .invoice,
        .client {
            display: flex;
            flex-direction: column;
            gap: 1em;
        }
        .field {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .fullspace {
            width: 100%;
            height: 100%;
        }

        .coach {
            grid-area: 1 / 1 / 2 / 3;
        }
        .bankDetails {
            grid-area: 1 / 3 / 2 / 5;
        }
        .logo {
            grid-area: 1 / 5 / 2 / 7;
            display: flex;
            justify-content: center;
            img {
                width: 250px;
                height: fit-content;
                padding: 1em;
            }
        }
        .invoice {
            grid-area: 2 / 1 / 3 / 4;
        }
        .client {
            grid-area: 2 / 4 / 3 / 7;
        }
    }
}
.invoiceFinal {
    display: flex;
    flex-direction: column;
    .view {
        height: 500px;
        max-height: 100%;
        display: flex;
        border-radius: 15px;
        #overview {
            width: 50%;
            overflow: hidden;

            border-radius: 15px;
        }
        .export {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 1em;
            button {
                width: 50%;
            }
        }
    }
    .but {
        margin-top: 1em;
        width: 100%;
        justify-content: center;
        display: flex;
    }
}
