.Whiteboard {
    &-topbar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 1em 0;
        flex-wrap: wrap;
        row-gap: 1em;
        .left {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1em;
            h2 {
                margin: 0;
            }
            .button {
                margin-left: 5px;
            }
        }
        .adders {
            display: flex;
            justify-content: flex-start;
            .button {
                margin: auto 1em;
            }
        }
    }
    .scrolled {
        position: sticky;
        width: calc(100% + 2em);
        margin-left: -1em;
        padding: 1em 1em;
        z-index: 9;
        top: 0;
        background-color: white;
    }
    .wbNote {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .BItem {
            flex-basis: 90%;
        }
    }
}
