.FullAction {
    .strip {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 1em;
        border-bottom-left-radius: 0.28571429rem;
        border-top-left-radius: 0.28571429rem;
    }
    &-content {
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        grid-template-rows: 1fr auto;
        grid-column-gap: 1em;
        grid-row-gap: 1em;
        & > *:nth-child(1) {
            grid-area: 1 / 1 / 2 / 2;
        }
        & > *:nth-child(2) {
            grid-area: 1 / 2 / 2 / 3;
        }
        & > *:nth-child(3) {
            grid-area: 1 / 3 / 2 / 6;
        }
        & > *:nth-child(4) {
            grid-area: 1 / 6 / 2 / 10;
        }
        & > *:nth-child(5) {
            grid-area: 1 / 10 / 2 / 11;
            text-align: right;
        }
    }
    i {
        justify-self: end;
    }
    .ui.fluid.dropdown {
        min-width: 7em;
    }
    @media screen and (max-width: 700px) {
        &-content {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto auto 1fr auto;
            grid-column-gap: 0;
            grid-row-gap: 0.25em;
            & > *:nth-child(1) {
                grid-area: 1 / 1 / 2 / 2;
                font-weight: bold;
            }
            & > *:nth-child(2) {
                grid-area: 1 / 2 / 2 / 3;
                font-weight: bold;

                text-align: right;
            }
            & > *:nth-child(3) {
                grid-area: 2 / 1 / 3 / 3;
                font-weight: bold;
            }
            & > *:nth-child(4) {
                grid-area: 3 / 1 / 4 / 3;
            }
            & > *:nth-child(5) {
                grid-area: 4 / 1 / 5 / 3;
                text-align: left;
            }
        }
    }
}
