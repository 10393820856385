.home {
    padding: 20px;
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: center;
    margin: auto;
    .header-container {
        display: flex;
        justify-content: space-between;
        .right {
            display: flex;
            gap: 1em;
        }
    }
    @media screen and (max-width: 700px) {
        padding: 0px;
        width: 100%;
        .ui.container {
            width: 100vw !important;
            margin: 0 !important;
        }
    }
}

.gdpr-column {
    display: flex;
    flex-direction: column;
    gap: 1em;
    .accept {
        align-self: center;
    }
}
