.SessionNotes {
    &-topbar {
        padding: 1em 0;
        padding-bottom: 0em;
        .left {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            gap: 1em;
            .statusFilter {
                width: 8em !important;
            }

            h2 {
                margin: 0;
                color: black;
            }
            .button {
                margin-left: 5px;
            }
        }
        .adders {
            display: flex;
            justify-content: start;
            .button {
                margin-top: 1em;
            }
        }
    }
    .session-header {
        padding: 1em 1.5em;
        margin-top: 0.5em;
        font-weight: bold;
        background-color: white;
        color: var(--card-text-color);
        border-radius: 0.28571429rem;
        &-notes {
            display: grid;
            grid-template-columns: repeat(18, 1fr);
            grid-template-rows: repeat(1, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 1em;
            & > *:nth-child(1) {
                grid-area: 1 / 1 / 2 / 2;
            }
            & > *:nth-child(2) {
                grid-area: 1 / 2 / 2 / 5;
            }
            & > *:nth-child(3) {
                grid-area: 1 / 5 / 2 / 8;
            }
            & > *:nth-child(4) {
                grid-area: 1 / 8 / 2 / 18;
            }
            & > *:nth-child(5) {
                grid-area: 1 / 18 / 2 / 19;
                align-self: flex-end;
            }
        }
        &-actions {
            display: grid;
            grid-template-columns: repeat(10, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 1em;
            grid-row-gap: 0px;
            & > *:nth-child(1) {
                grid-area: 1 / 1 / 2 / 2;
            }
            & > *:nth-child(2) {
                grid-area: 1 / 2 / 2 / 3;
            }
            & > *:nth-child(3) {
                grid-area: 1 / 3 / 2 / 6;
            }
            & > *:nth-child(4) {
                grid-area: 1 / 6 / 2 / 10;
            }
            & > *:nth-child(5) {
                grid-area: 1 / 10 / 2 / 11;
                text-align: right;
            }
        }
    }

    .scrolled {
        position: sticky;
        width: calc(100% + 2em);
        margin-left: -1em;
        padding: 1em 1em;
        padding-bottom: 0 !important;
        z-index: 9;
        top: 0;
        background-color: white;
    }
    &-content {
        .ui.form textarea {
            height: 5em;
        }
        .Actions {
            .editing {
                gap: 1em;
                .grow-5 {
                    flex-grow: 4;
                    .ui input {
                        width: 100%;
                    }
                    .ui form {
                        width: 100%;
                    }
                }
                margin-top: 1em;
            }
        }
        .Note {
            &-content,
            &-content-editing {
                display: grid;
                grid-template-columns: repeat(18, 1fr);
                grid-template-rows: repeat(1, 1fr);
                grid-column-gap: 1em;
                grid-row-gap: 1em;
                & > *:nth-child(1) {
                    grid-area: 1 / 1 / 2 / 2;
                }
                & > *:nth-child(2) {
                    grid-area: 1 / 2 / 2 / 5;
                }
                & > *:nth-child(3) {
                    grid-area: 1 / 5 / 2 / 8;
                }
                & > *:nth-child(4) {
                    grid-area: 1 / 8 / 2 / 18;
                }
                & > *:nth-child(5) {
                    grid-area: 1 / 18 / 2 / 19;
                    align-self: center;
                }
                .ui.form {
                    display: flex;
                    gap: 1em;
                    align-items: center;
                }
                .buttons {
                    display: flex;
                    gap: 1em;
                    justify-content: flex-end;
                }
                @media screen and (max-width: 700px) {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: auto, auto, 1fr, auto;
                    grid-column-gap: 0px;
                    grid-row-gap: 0.25em;
                    .ui.form {
                        display: flex;
                        flex-direction: column;
                    }
                    & > *:nth-child(1) {
                        grid-area: 1 / 1 / 2 / 2;
                        font-weight: bold;
                    }
                    & > *:nth-child(2) {
                        text-align: right;
                        grid-area: 1 / 2 / 2 / 3;
                        font-weight: bold;
                    }
                    & > *:nth-child(3) {
                        grid-area: 2 / 1 / 3 / 3;
                        font-weight: bold;
                    }
                    & > *:nth-child(4) {
                        grid-area: 3 / 1 / 4 / 3;
                    }
                    & > *:nth-child(5) {
                        grid-area: 4 / 1 / 5 / 3;
                        align-self: center;
                    }
                }
            }
            .radios {
                display: flex;
                flex-direction: column;
                gap: 1em;
                justify-content: start;
            }
            .locked {
                pointer-events: none;
            }
        }
        .Actions {
            &-top {
                display: flex;
                align-items: center;
                gap: 1em;
                .ui.header {
                    color: var(--card-header-color);
                }
                .adders {
                    display: flex;
                }
            }
            .actionList {
                margin-top: 1em;
                .Action {
                    display: flex;
                    justify-content: space-between;
                    background-color: var(--card-background-color);
                    // border: 2px solid var(--card-text-color);
                    border-radius: 5px;
                    gap: 1em;
                    padding-left: 2em;

                    .title {
                        flex: 4 1;
                    }
                    .status {
                        flex: 1 1;
                        min-width: fit;
                    }
                    .ui .input {
                        width: 100%;
                    }

                    .ui.fluid.dropdown {
                        min-width: 10rem;
                    }
                    .status {
                        display: flex;
                        justify-content: flex-end;
                        * {
                            margin: 0 1em;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 700px) {
        &-topbar {
            .left .search {
                width: 100%;
            }
            .session-header {
                display: none;
            }
        }
        &-topbar,
        .scrolled {
            padding-bottom: 1em !important;
        }

        // .Note-content,
        // .Note-content-editing {
        //     display: grid;
        //     grid-template-columns: repeat(2, 1fr);
        //     grid-template-rows: repeat(4, 1fr);
        //     grid-column-gap: 0px;
        //     grid-row-gap: 0px;
        //     .ui.form {
        //         textarea {
        //             height: 13em;
        //         }
        //     }
        //     .button {
        //         align-self: baseline;
        //     }
        // }
        .Note-content-editing {
            .BItem {
                width: 100%;
                .ui .input {
                    width: 100%;
                }
            }
        }
        .Actions {
            width: 100%;

            &-top {
                display: flex;
                flex-direction: column;
            }
            .Action {
                flex-direction: column-reverse;
                .ui.form {
                    textarea {
                        height: 8em;
                    }
                }
                .status {
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: space-between !important;
                    * {
                        margin: 0 !important;
                    }
                }
            }
            .ui.selection.dropdown > .delete.icon,
            .ui.selection.dropdown > .dropdown.icon,
            .ui.selection.dropdown > .search.icon {
                padding: 0 !important;
            }
        }
    }
}
