.Progress {
    &-topbar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 1em 0;
        flex-wrap: wrap;
        row-gap: 1em;
        .left {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1em;
            h2 {
                margin: 0;
            }
        }
    }
    &-main {
        padding: 0.5em;
        &-bar {
            margin: 0.5em 0;
            width: 100%;
            display: grid;
            grid-template-rows: 1fr;
            grid-column-gap: 1em;
            grid-row-gap: 0px;
            grid-template-columns: 1fr 5fr;
            align-items: center;
            &-level0 {
                .bar {
                    // background-color: #be0f34 !important;
                    background-color: #fb8113 !important;
                }
            }
            &-level1 {
                .bar {
                    background-color: #fb8113 !important;
                }
            }
            &-level2 {
                .bar {
                    background-color: #f5cf47 !important;
                }
            }
            &-level3 {
                .bar {
                    background-color: #0f7361 !important;
                }
            }
        }
    }
    .P {
        margin: 1em 0;
        display: flex;
        flex-direction: column;
        .row-grid {
            display: grid;
            grid-template-rows: 1fr;
            grid-column-gap: 1em;
            grid-row-gap: 0px;
            grid-template-columns: 3fr 3fr;
            align-items: center;
            &-bar {
                grid-column-gap: 0em;
                grid-template-columns: 1.5fr 4.5fr;
                &-level0 {
                    .bar {
                        // background-color: #be0f34 !important;
                        background-color: #fb8113 !important;
                    }
                }
                &-level1 {
                    .bar {
                        background-color: #fb8113 !important;
                    }
                }
                &-level2 {
                    .bar {
                        background-color: #f5cf47 !important;
                    }
                }
                &-level3 {
                    .bar {
                        background-color: #0f7361 !important;
                    }
                }
            }
            .data {
                display: grid;
                grid-template-rows: 1fr;
                grid-column-gap: 1em;
                grid-row-gap: 0px;
                grid-template-columns: 2fr 1fr 1fr;
                align-items: center;
            }
            .level {
                // input {
                //     accent-color: #3277ae !important;
                // }
                .disabled {
                    pointer-events: none !important;
                    label {
                        opacity: 1 !important;
                    }
                    opacity: 1 !important;
                }

                &-level0 {
                    .input {
                        // accent-color: #be0f34 !important;
                        accent-color: #fb8113 !important;
                    }
                }
                &-level1 {
                    .input {
                        accent-color: #fb8113 !important;
                    }
                }
                &-level2 {
                    .input {
                        accent-color: #f5cf47 !important;
                    }
                }
                &-level3 {
                    .input {
                        accent-color: #0f7361 !important;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 700px) {
        &-main {
            &-bar {
                display: flex;
                flex-direction: column;
                justify-content: stretch;
                align-content: stretch;
                .progress {
                    width: 100%;
                }
            }
        }
        .P {
            .row-grid {
                display: flex;
                flex-wrap: wrap;
                .data {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    padding: 1em 0em;
                }
                .level {
                    margin-top: 1em;
                    width: 100%;
                }
                &-bar {
                    .progress {
                        width: 100%;
                    }
                }
            }
        }
    }
}
