.fade-out{
    opacity: 0;
    transition: opacity 1s ease-out;
}
.alertContainer{
    margin: 10px;
    pointer-events: all;
}
.delete{
    display: none;
}