.Roadmap {
    &-topbar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 1em 0;
        flex-wrap: wrap;
        row-gap: 1em;
        .left {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1em;
            h2 {
                margin: 0;
            }
            .button {
                margin-left: 5px;
            }
        }

        .adders {
            display: flex;
            justify-content: flex-start;
            .button {
                margin: auto 1em;
            }
        }
    }
    .scrolled {
        position: sticky;
        width: calc(100% + 2em);
        margin-left: -1em;
        padding: 1em 1em;
        z-index: 9;
        top: 0;
        background-color: white;
    }
    .iconText {
        display: flex;
        justify-content: space-between;
        .icon {
            margin-top: -5px;
            align-self: center;
        }
    }
    .roadmapCard {
        &-content {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            grid-template-rows: auto auto repeat(3, 1fr) auto;
            grid-column-gap: 1em;
            grid-row-gap: 0.5em;
            :nth-child(3) {
                padding-bottom: 1em;
            }
            .topic {
                grid-area: 1 / 1 / 2 / 4;
            }
            .byWhen {
                justify-self: end;
                grid-area: 1 / 4 / 2 / 7;
            }
            .priority {
                grid-area: 2 / 1 / 3 / 4;
            }
            .status {
                justify-self: end;
                grid-area: 2 / 4 / 3 / 7;
            }
            .objective {
                grid-area: 3 / 1 / 5 / 3;
            }
            .impact {
                grid-area: 5 / 1 / 7 / 3;
            }
            .actions {
                grid-area: 3 / 3 / 7 / 5;
            }
            .kpi {
                grid-area: 3 / 5 / 7 / 7;
            }
            @media screen and (max-width: 700px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(6, auto);
                grid-column-gap: 0px;
                grid-row-gap: 0.5em;
                .topic {
                    grid-area: 1 / 1 / 2 / 2;
                }
                .byWhen {
                    justify-self: end;
                    text-align: right;
                    grid-area: 1 / 2 / 2 / 3;
                }
                .priority {
                    grid-area: 2 / 1 / 3 / 2;
                }
                .status {
                    justify-self: end;
                    grid-area: 2 / 2 / 3 / 3;
                }
                .objective {
                    grid-area: 3 / 1 / 4 / 3;
                }
                .impact {
                    grid-area: 4 / 1 / 5 / 3;
                }
                .actions {
                    grid-area: 5 / 1 / 6 / 3;
                }
                .kpi {
                    grid-area: 6 / 1 / 7 / 3;
                }
            }
        }
    }
}
