.Branding {
    label {
        padding: 0 1em 0 0;
        font-weight: bold;
        text-transform: capitalize;
    }
    .circle {
        align-self: center;
        margin: 0 1em;
        width: 1rem;
        height: 1rem;
        display: flex;
        border-radius: 50%;
    }
    .color {
        display: flex;
        &-edit {
            position: relative;
        }
    }
    .card {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 1em;
    }
    .Coach-topbar,
    .logo-topbar {
        .bar-segment {
            display: flex;
            flex-wrap: wrap;
            gap: 1em;
        }
    }
    .logo-topbar {
        // display: flex;
        // gap: 1em;
        // align-items: center;
        margin-bottom: 1em;
        // h3 {
        //     margin: 0;
        // }
    }
    .logo-container {
        max-width: 300px;
        height: 100%;
        padding: 1em;
        background-color: grey;
        border-radius: 0.5em;
        img {
            max-width: 100%;
            height: auto;
        }
    }
    .colorContainer {
        display: flex;
        flex-direction: row;
        width: 32%;
        gap: 1em;
        .colorComponent {
            padding: 1em 1em;
            width: 100%;
            border-radius: 1rem;
            display: grid;
            min-height: 100px;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            .buttons {
                display: flex;
                justify-content: end;
            }
        }
        @media screen and (max-width: 700px) {
            width: 100%;
        }
    }
    @media screen and (max-width: 700px) {
        width: 100%;
    }
}
.colorModal {
    .header {
        display: flex !important;
        justify-content: space-between;
    }
    .content {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        gap: 1em;
    }
}
.rcp {
    @media screen and (max-width: 700px) {
        max-width: 100% !important;
    }
}
i.icon.cancel:before {
    cursor: pointer;
}
