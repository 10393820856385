.register {
    font-family: Montserrat !important;
    padding: 20px;
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: center;
    margin: auto;
    .header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    @media screen and (max-width: 700px) {
        padding: 0px;
        width: 100%;
        .ui.container {
            width: 100% !important;
            margin: 0 !important;
        }
    }
}
