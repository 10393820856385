.headerx {
    margin: 0;
    padding: 0 20px;
    height: 100%;
    background-color: transparent;
    // box-shadow: 0px 3px 8px -1px #000000af;
    // border-bottom: 0.0625rem solid #e9ecef;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #f0f5f8;
    &-landing {
        // background-color: #fff;s
    }
    div {
        flex: 1 1 0;
        width: 0;
    }
    .userInfo {
        text-align: right;
        display: flex;
        gap: 1em;
        justify-content: end;
    }
    img {
        height: 40px;
        width: auto;
        cursor: pointer;
    }
    @media screen and (max-width: 700px) {
        .userInfo {
            span {
                display: none;
            }
        }
        .role {
            display: none;
        }
    }
}
