// @font-face {
//   font-family: "PTSans";
//   src: local("PTSans"), url(./fonts/PTSans-Regular.ttf) format("truetype");
// }
// @font-face {
//   font-family: "PTSans-bold";
//   src: local("PTSans-bold"), url(./fonts/PTSans-Bold.ttf) format("truetype");
// }
// @font-face {
//   font-family: "EBGaramond";
//   src: url(./fonts/EBGaramond-VariableFont_wght.ttf) format("truetype");
// }
@font-face {
  font-family: "Montserrat";
  src: url(./fonts/Montserrat-VariableFont_wght.ttf) format("truetype");
}

:root {
  overflow-x: auto !important;

  // overflow-y: hidden;
  min-height: 100vh;
}

// @font-face {
//   font-family: "Quicksand";
//   src: url(./fonts/Quicksand-VariableFont_wght.ttf) format("truetype");
// }
// :root {
//   --primary-color: #122f53;
//   --primary-color-hover: #1d477b;
//   --primary-color-text: #ffffff;
//   --secondary-color: #a79d96;
//   --secondary-color-hover: white;
//   --secondary-color-text: black;
//   --main-background-color: #ffff;
//   --container-background-color: #ffffff;
//   --card-background-color: #fff;
//   --card-text-color: #000000;
//   --card-header-color: #122f53;
//   --tab-background-color: #ffffff;
//   --border: #d4d4d5;
// }
// .rectangle {
//   width: 10px;
//   height: 10px;
// }
// h1,
// h2,
// h3,
// h4 {
//   font-family: Montserrat !important;
// }
// .main {
//   // margin-top: 80px;
//   height: calc(100vh);
//   background: white;
//   background-size: cover;
//   justify-content: center;
//   display: block;
//   overflow: auto;
//   font-family: Quicksand !important;
//   font-weight: 500;
//   font-size: 1.2em;
//   .ui.toggle.checkbox input:focus:checked ~ label:before,
//   .ui.toggle.checkbox input:checked ~ label:before {
//     background-color: #0f7361 !important;
//   }
// }
// input,
// textarea,
// .dropdown {
//   font-size: 0.9rem !important;
// }

// .ui.message,
// .ui.segment,
// label,
// .ui.form {
//   font-size: 1.2rem !important;
// }
// .ui.table {
//   font-size: 1rem !important;
//   thead {
//     font-size: 1rem !important;
//   }
// }

//  thead {
//   background-color: var(--secondary-color) !important;
//   tr{

//     border-radius: 0.25rem !important;
//   }
//   th {
//     color: #f0f5f8 !important;
//     background-color: var(--secondary-color) !important;
//   }
// }
// .ui.table tfoot {
//   background-color: var(--secondary-color) !important;
//   th {
//     color: #f0f5f8 !important;
//     background-color: var(--secondary-color) !important;
//   }
// }
// .ui.table .bg-white {
//   visibility: hidden;
//   background-color: white !important;

//   th {
//     height: 0;
//     padding: 0;
//     font-size: 0.1rem;
//   }
//   color: #f0f5f8 !important;
//   background-color: white !important;
// }

// .ui.button {
//   &,
//   .hFont {
//     font-family: Montserrat !important;
//   }
//   font-family: Quicksand !important;
//   width: fit-content;
//   height: fit-content;
//   color: var(--secondary-color-text) !important;
//   background: var(--secondary-color) !important;
//   background-color: var(--secondary-color) !important;
//   &:hover {
//     background-color: var(--secondary-color-hover) !important;
//   }
//   &.green {
//     background-color: #0f7361 !important;

//     &:hover {
//       filter: brightness(150%) !important;
//     }
//   }
//   &.red {
//     background-color: #be0f34 !important;

//     &:hover {
//       background-color: #c7302b !important;
//     }
//   }
//   &.blue {
//     &:hover {
//       background-color: #3277ae !important;
//     }
//     background-color: #122f53 !important;
//   }
// }
// .ui.button,
// .inverted {
//   color: var(--secondary-color-text) !important;
//   background-color: var(--secondary-color-hover) !important;
//   &:hover {
//     background: var(--secondary-color) !important;
//   }
// }
// .ui.button.primary-button {
//   color: var(--secondary-color-text) !important;
//   background-color: white !important;
//   &:hover {
//     background: white !important;
//   }
// }
// .radios {
//   .ui.toggle.checkbox input:checked ~ label:before {
//     background-color: #3277ae !important;
//   }
// }
// div,
// p,
// label,
// span {
//   word-break: break-word !important;
// }
// h2 {
//   color: var(--primary-color) !important;
// }
// .ui.menu .item {
//   padding: 0.92857143em 1em !important;
// }
// .ui.header {
//   color: black !important;
// }
// .ui.message {
//   .header {
//     color: var(--card-header-color) !important;
//   }
//   border: 0.0625rem solid #e9ecef !important;
//   background: var(--card-background-color) !important;
//   color: var(--card-text-color) !important;
// }
// .ui.container {
//   box-shadow: 0px 3px 8px -1px #000000af;
//   // border: 2px solid #f3f1ec;
//   background-color: var(--container-background-color);
//   border-radius: 0.5rem;
//   height: 100%;
//   padding: 20px;

//   @media screen and (max-width: 700px) {
//     width: 100vw !important;
//     margin: 0px !important;
//     padding: 1em 0.5em;
//   }
// }
// tr.folder,
// tr.file {
//   cursor: pointer !important;
// }
// tr.folder {
//   background-color: #f3f1ee;
// }
// tr.file {
//   td {
//     padding-left: 4em !important;
//   }
//   &:hover {
//     background-color: #a79d96 !important;

//     color: white !important;
//   }
// }
// .bold {
//   font-weight: bolder !important;
// }
// .tleft {
//   text-align: left !important;
//   align-items: baseline;
// }
// .newLines {
//   white-space: pre-line;
// }
// @media only screen and (min-width: 1200px) {
//   .ui.container {
//     width: 1300px !important;
//     margin-left: auto !important;
//     margin-right: auto !important;
//   }
// }
// .blue {
//   &-dark {
//     background-color: #122f53 !important;
//   }
//   background-color: #3277ae !important;
// }
// .count {
//   display: flex !important;
//   gap: 0.5em !important;
//   span {
//     font-size: 0.85em;
//   }
// }
// // Opened : 'bruin', Started: 'geel', 'On hold/ Delayed: oranje', 'completed: groen', 'cancelled: rood
// .Opened,
// .notstarted {
//   background-color: #a79d96 !important;
// }
// .Started,
// .started,
// .ontrack {
//   background-color: #f5cf47 !important;
// }
// .Onhold,
// .Delayed,
// .delayed {
//   background-color: #fb8113 !important;
// }
// .Completed,
// .ahead,
// .completed {
//   background-color: #0f7361 !important;
// }
// .Cancelled,
// .cancelled {
//   background-color: black;
// }

// .locked {
//   pointer-events: none;
// }

// @media screen and (max-width: 700px) {
//   .mobile-hidden {
//     display: none !important;
//   }
// }
// .color-blue {
//   color: #122f53;
// }
// #modal {
//   width: 88% !important;
// }
// .scrolled {
//   position: sticky;
//   width: calc(100% + 2em);
//   margin-left: -1em;
//   padding: 1em 1em !important;
//   z-index: 9;
//   top: -1em;
//   background-color: white;
// }
// .topbar {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: space-between;
//   padding: 1em 0;
//   flex-wrap: wrap;
//   .left {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     gap: 1em;
//     h2 {
//       margin: 0 !important;
//     }
//     .button {
//       margin-left: 5px;
//     }
//   }
//   .adders {
//     display: flex;
//     justify-content: flex-start;
//     .button {
//       margin: auto 1em;
//     }
//   }
// }

// .mb-only {
//   color: #a79d96 !important;
// }
// @media screen and (min-width: 700px) {
//   .mb-only {
//     display: none;
//   }
// }
// .tab {
//   display: flex;
// }
// .indicator {
//   display: table;
//   margin-left: 0.2em;
// }
// .alert-indicator {
//   display: table-cell;
//   vertical-align: middle;
//   text-align: center;
//   color: #872434;
// }
// .ui.tabular.menu {
//   border-color: var(--border) !important;
//   .item {
//     .tab {
//       background-color: var(--tab-background-color) !important;
//       color: var(--primary-color);
//     }

//     border-color: var(--border) !important;
//     border-left: 1px solid var(--border) !important;
//     border-right: 1px solid var(--border) !important;
//     border-top: 1px solid var(--border) !important;
//     border-radius: 0.28571429rem 0.28571429rem 0 0 !important;
//     background-color: var(--tab-background-color) !important;
//   }
// }
// .ui.segment[class*="bottom attached"] {
//   background-color: var(--tab-background-color) !important;
//   border-color: var(--border) !important;
// }
// .mobile-only {
//   @media screen and (min-width: 700px) {
//     display: none;
//   }
// }

// .strip {
//   position: absolute;
//   border-bottom-left-radius: 5px;
//   border-top-left-radius: 5px;
//   left: 0;
//   top: 0;
//   height: 100%;
//   width: 1em;
// }
// .flex-tr{
//   display: flex;
//   flex-direction: column;
// }
