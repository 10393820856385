.imageUpload {
    height: 100%;
    &-buttons {
        display: flex;
        gap: 1em;
        align-items: center;
        margin-bottom: 1em;
        h3 {
            margin: 0;
        }
    }
    &-container {
        // max-width: 400px;
        width: fit-content;
        background-color: var(--secondary-color);
        height: 100%;
        // padding: 1em;
        // background-color: grey;
        // overflow: hidden;
        display: flex;
        align-items: center;
        border-radius: 15px;
        padding: 1em;
        img {
            height: 100%;
            max-height: 100px;
            width: auto;
        }
    }
}
