.DocumentStorage {
    &-topbar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 1em 0;
        flex-wrap: wrap;
        row-gap: 1em;
        .left {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1em;
            h2 {
                margin: 0;
            }
            .button {
                margin-left: 5px;
            }
        }
        .adders {
            display: flex;
            justify-content: flex-start;
            .button {
                margin: auto 1em;
            }
        }
    }
    .scrolled {
        position: sticky;
        width: calc(100% + 2em);
        margin-left: -1em;
        padding: 1em 1em;
        z-index: 9;
        top: 0;
        background-color: white;
    }
    .panes {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        width: 100%;
        height: 100%;
    }
    .selectedFile {
        display: flex;
        justify-content: row;
        gap: 1em;
        align-items: center;
        p:first-child {
            font-weight: bold;
        }
        p {
            margin: 0 !important;
        }
    }
    .DragAndDrop__area {
        border: 1px #d4d4d5 dashed;
        border-radius: 0.28571429rem;
    }
    .selectedFolder {
        background-color: #c7c2bc;
    }
    .shared-folder {
        background: #f3f1ee !important;
        .shared-folder:nth-of-type(1) {
            padding-left: 1em;
            width: 100%;
            background: #fff;
            margin: 0 !important;
            border: 0 !important;
            box-shadow: none;
            border-radius: 0.28571429rem;
            text-align: left;
            color: rgba(0, 0, 0, 0.87);
            border-collapse: separate;
            border-spacing: 0;
        }
    }
    .filec {
        height: fit-content !important;
        .file {
            div {
                max-height: fit-content;
            }
        }
    }
    &-content {
        .ui.table,
        .ui.placeholder.segment {
            margin: 0 !important;
        }
        .file i {
            margin: 0 0.5em !important;
        }
    }
}
