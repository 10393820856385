.Timesheet {
    .overlay {
        position: fixed;
        background-color: rgba(0, 0, 0, 0.266);
        width: 100vw;
        height: calc(100vh - 60px);
        overflow-y: scroll;
        display: flex;
        left: 0;
        top: 80px;
        justify-content: baseline;
        flex-direction: column;
        z-index: 300;
        .container {
            margin: 2em 2em;

            background-color: var(--container-background-color);
            padding: 1em;
            border-radius: 0.5em;
        }
    }
    table {
        @media screen and (max-width: 700px) {
            display: none;
        }
    }
    &-topbar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 1em 0;
        flex-wrap: wrap;
        row-gap: 1em;
        .left {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1em;
            h2 {
                margin: 0;
            }
            .button {
                margin-left: 5px;
            }
        }
        .adders {
            display: flex;
            justify-content: flex-start;
            .button {
                margin: auto 1em;
            }
        }
    }
    .scrolled {
        top: 0em;
    }
}
