.Intake {
    &-topbar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 1em 0;
        flex-wrap: wrap;
        row-gap: 1em;
        .left {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1em;
            h2 {
                margin: 0;
            }
            .button {
                margin-left: 5px;
            }
        }
        .adders {
            display: flex;
            justify-content: flex-start;
            .button {
                margin: auto 1em;
            }
        }
    }
    .scrolled {
        position: sticky;
        width: calc(100% + 2em);
        margin-left: -1em;
        padding: 1em 1em;
        z-index: 9;
        top: 0;
        background-color: white;
    }
    &-content {
        .ui.form textarea {
            height: 5em;
        }

        .meta {
            margin-top: 1em;
            display: flex;
            flex-direction: row;
            gap: 1em;
            .MD {
                display: flex;
                flex-direction: row;
                gap: 0.5em;
                align-items: center;
            }

            .BItem {
                height: 100%;

                .input {
                    height: 100%;
                }
            }
        }
        .IQ {
            margin-top: 1em;
            .client {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                .icon {
                    margin-right: 1em;
                    color: var(--primary-color);
                }
            }
            .editing {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 2em;

                form {
                    flex: 2 0 auto;
                }
            }
        }
        .GQ {
            .editing {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 2em;
                form {
                    flex: 2 0 auto;
                }
            }
        }
        .AS {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .ui.form {
                flex-basis: 90%;
            }
            > * {
                margin: 0.5em;
            }
            .locked {
                pointer-events: none;
            }
        }

        .IandC {
            .editing {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 2em;
                .ui.input {
                    width: 100% !important;
                }
            }
            label {
                font-weight: 900;
            }
        }
        .radios {
            display: flex;
            flex-direction: column;
        }
    }
    @media screen and (max-width: 700px) {
        &-content .meta {
            flex-direction: column;
            .MD {
                justify-content: space-between;
            }
        }
        &-topbar {
            .editButton {
                display: none;
            }
        }
    }
}
