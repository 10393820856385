.alertsContainer {
    display: flex;
    position: absolute;
    width: 80%;
    height: 100vh;
    flex-direction: column-reverse;
    pointer-events: none;
    z-index: 3;
    top: 0;
    left: 10%;
    .ui.attached.message {
        background-color: var(--card-background-color);
    }
}
