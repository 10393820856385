.mobile-client-menu {
    position: fixed;
    width: fit-content;
    height: fit-content;
    bottom: 2em;
    right: 2em;
    overflow: hidden;
    z-index: 10;

    @media screen and (min-width: 1300px) {
        display: none;
    }
}

.mobile-client-tabs {
    .hidden {
        visibility: hidden;
    }
    .visible {
        visibility: visible;
        height: fit-content;
        backdrop-filter: brightness(0.3);
    }
    .tabs {
        position: absolute;
        width: 100vw;
        top: 80px;
        min-height: calc(100vh - 80px);
        transition: height 0.5s;
        z-index: 9;
        .item {
            background-color: white !important;
            text-align: center;
            border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;
        }
        .active {
            background-color: #a79d96 !important;
            // background-color: rgba(0, 0, 0, 0.15) !important;
        }
    }
}
