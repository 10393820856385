@media screen and (max-width: 700px) {
    .client {
        display: none;
    }
}
@media screen and (max-width: 1300px) {
    .client {
        .ui.attached.menu {
            display: none;
        }
    }
}
@media screen and (min-width: 701px) {
    .client-mobile {
        display: none;
    }
}
