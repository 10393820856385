.overview {
    display: flex;
    flex-direction: column;
    gap: 2em;
    &-header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .right .button {
            margin-left: 2em;
            @media screen and (max-width: 700px) {
                margin-left: 0;
            }
        }
    }

    &-summary {
        display: flex;
        justify-content: space-around;
        margin-top: 1em;
        > h4 {
            color: var(--primary-color-text);
            text-align: center;
            margin: 0 !important;
        }
        @media screen and (max-width: 700px) {
            flex-direction: column;
            > h4 {
                text-align: left;
            }
        }
    }
    @media screen and (max-width: 700px) {
        &-header-container {
            display: flex;
            flex-direction: column;
            .right {
                display: flex;
                justify-content: space-between;
            }
        }
        .ui.table thead {
            display: none !important;
        }
    }
    @media screen and (max-width: 700px) {
        width: 100vw !important;
        margin: 0px !important;
        padding: 1em 0.5em;
    }
}
